import {
  Button,
  ContentContainer,
  Header,
  LoadingSpinner,
  WebcamPhotoCapture,
} from '../../components/Generic'
import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useRef, useState } from 'react'
import { StoreContext } from '../../components/App'
import { devLog } from '../../methods/devLog'
import { forceRedirect } from '../../methods/forceRedirect'
import {
  ButtonText,
  LoadingOverlap,
  LoadingOverlapWrapper,
  ParagraphCentered,
  UploadParagraph,
} from '../../styles/generic.styles'
import {
  CenteredContent,
  ContentContainerBottomFixed,
  MarginOnly,
  Margined,
  MarginedWithHeight,
} from '../../styles/layout.styles'

export const AddressUploadBothSidesIkanoPage = observer(() => {
  const [wrapperKey, setWrapperKey] = useState(new Date().getTime())

  const store = useContext(StoreContext)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const { context } = store.ScenarioState
  const { theme } = store.InterfaceState
  const {
    AddressUploadFrontSideIkano: transFront,
    AddressUploadBackSideIkano: transBack,
  } = store.TranslationsState.translations

  const webcamRef = useRef<WebcamPhotoCapture>(null)
  const [loading, setLoading] = useState(false)

  const [currentPhotoStep, setCurrentPhotoStep] = useState<'front' | 'back'>(
    'front'
  )

  const [buttonStep, setButtonStep] = useState<'take' | 'confirm'>('take')

  const [frontPhotoData, setFrontPhotoData] = useState<
    string | Blob | undefined
  >()
  const [backPhotoData, setBackPhotoData] = useState<
    string | Blob | undefined
  >()

  const handleTakePhoto = async () => {
    try {
      await webcamRef.current?.takePhoto()
      setButtonStep('confirm')
    } catch (error) {
      setErrorMessage(transFront.errorMessage)
    }
  }

  const handleSavingPhoto = async (photoData: string | Blob) => {
    if (currentPhotoStep === 'front') {
      setFrontPhotoData(photoData)
    } else {
      setBackPhotoData(photoData)
    }
  }

  const handleReset = () => {
    try {
      webcamRef.current?.reset()
      setButtonStep('take')

      if (currentPhotoStep === 'front') {
        setFrontPhotoData(undefined)
      } else {
        setBackPhotoData(undefined)
      }
    } catch (error) {
      setErrorMessage(transFront.errorMessage)
    }
  }

  const handleProceed = async () => {
    setLoading(true)

    try {
      if (currentPhotoStep === 'front' && frontPhotoData) {
        await handlePhotoUpload(frontPhotoData)
        handleReset()
        setCurrentPhotoStep('back')
      } else if (currentPhotoStep === 'back' && backPhotoData) {
        await handlePhotoUpload(backPhotoData)

        forceRedirect(`${process.env.WEB_API_URL}/pixel-speak/complete`)
      }
    } catch (error) {
      // error handling with retry button
      setErrorMessage(transFront.errorMessage)
    }

    setLoading(false)
  }

  const handlePhotoUpload = async (photoData: string | Blob) => {
    try {
      await axios.post(
        context.documentUploadUrl,
        { file: photoData },
        {
          withCredentials: true,
        }
      )
    } catch (e) {
      devLog(e)
    }
  }

  const resetComponent = () => {
    setLoading(false)
    setCurrentPhotoStep('front')
    setButtonStep('take')
    setFrontPhotoData(undefined)
    setBackPhotoData(undefined)
    setErrorMessage(undefined)
    webcamRef.current?.reset()

    setWrapperKey(new Date().getTime())
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
      key={wrapperKey}
    >
      {
        <>
          {/* Headline */}
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
          >
            {currentPhotoStep === 'front'
              ? transFront.header
              : transBack.header}
          </Header>

          {!errorMessage?.length && (
            <>
              {buttonStep === 'confirm' ? (
                <MarginOnly margin="20px 0 20px 0">
                  <UploadParagraph>
                    {currentPhotoStep === 'front'
                      ? transFront.descriptionConfirm
                      : transBack.descriptionConfirm}
                  </UploadParagraph>
                </MarginOnly>
              ) : (
                <MarginOnly margin="20px 0 20px 0">
                  <UploadParagraph>
                    {currentPhotoStep === 'front'
                      ? transFront.description
                      : transBack.description}
                  </UploadParagraph>
                </MarginOnly>
              )}
            </>
          )}

          {/* Camera Component */}
          {!errorMessage?.length ? (
            <LoadingOverlapWrapper>
              <WebcamPhotoCapture
                viewerWidth="100%"
                ref={webcamRef}
                selectStyles={theme.selectInput}
                dotsHorizontalColor={theme.icon.docVerifyDotsColor}
                showFrame
                onPhotoCapture={(value) => handleSavingPhoto(value)}
                outputFormat="image/jpeg"
              />

              {loading && (
                <LoadingOverlap>
                  <MarginedWithHeight margin="0 0 15px" height="0">
                    <Header
                      {...theme.header}
                      fontFamily={theme.globals.fontFamilyHeadline}
                    >
                      {transFront.verifying}
                    </Header>
                  </MarginedWithHeight>
                  <LoadingSpinner
                    width="68px"
                    padding="100px 0"
                    {...theme.loadingSpinner}
                  />
                </LoadingOverlap>
              )}
            </LoadingOverlapWrapper>
          ) : (
            <ContentContainer padding="20px 0">
              <ParagraphCentered>{errorMessage}</ParagraphCentered>
              <CenteredContent>
                <Button {...theme.button} onClick={resetComponent}>
                  {transFront.errorRetry}
                </Button>
              </CenteredContent>
            </ContentContainer>
          )}

          <Margined margin="0 0 30px" />

          {/* Action Buttons */}
          {!errorMessage?.length && (
            <ContentContainerBottomFixed mobileGap="16px">
              {buttonStep === 'confirm' ? (
                <>
                  <Button
                    {...theme.button}
                    width="160px"
                    paddingMobile="14px 40px"
                    onClick={handleReset}
                  >
                    {transFront.retakePhoto}
                  </Button>

                  <Button
                    onClick={() => handleProceed()}
                    {...theme[
                      !frontPhotoData && !backPhotoData
                        ? 'buttonDisabled'
                        : 'button'
                    ]}
                    {...theme.button}
                    width="160px"
                    paddingMobile="14px 40px"
                  >
                    <>
                      <ButtonText>{transFront.proceed}</ButtonText>
                    </>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    {...theme.button}
                    width="164px"
                    paddingMobile="14px 40px"
                    onClick={handleTakePhoto}
                  >
                    {transFront.takePhoto}
                  </Button>
                </>
              )}
            </ContentContainerBottomFixed>
          )}
        </>
      }
    </ContentContainer>
  )
})
