import styled from 'styled-components'

interface ContainerProps {
  size: string
  primary?: string
  secondary?: string
  tenary?: string
}

export const Container = styled.div.attrs({
  className: 'authflow-symbol',
})<ContainerProps>`
  width: ${(props) => (props.size ? props.size : '18px')};
  height: ${(props) => (props.size ? props.size : '18px')};
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  svg {
    width: 100%;
    height: 100%;
  }

  &:last-child:not(span) {
    margin-left: auto;
  }

  svg .document {
    &--primary--stroke {
      stroke: ${(props) =>
        props.primary ? props.primary : 'rgba(65, 255, 209, 1)'};
    }

    &--primary--fill {
      fill: ${(props) =>
        props.primary ? props.primary : 'rgba(65, 255, 209, 1)'};
    }

    &--secondary--stroke {
      stroke: ${(props) => (props.secondary ? props.secondary : '#FFFFFF')};
    }

    &--secondary--fill {
      fill: ${(props) => (props.secondary ? props.secondary : '#FFFFFF')};
    }

    &--tenary--stroke {
      stroke: ${(props) => (props.tenary ? props.tenary : '#EDEEEF')};
    }

    &--tenary--fill {
      fill: ${(props) => (props.tenary ? props.tenary : '#EDEEEF')};
    }
  }
`
