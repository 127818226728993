import styled from 'styled-components'

interface ContainerProps {
  color?: string
  fontSizeMobile?: string
  lineHeightMobile?: string
  fontSize?: string
  lineHeight?: string
  margin?: string
  textAlign?: string
  width?: string
  fontFamily?: string

  flexSpaceBetween?: boolean
}

export const Container = styled.h1.attrs({
  className: 'authflow-header',
})<ContainerProps>`
  color: ${(props) => props.color || 'rgb(52, 53, 58)'};
  font-size: ${(props) => props.fontSize || '1.625rem'};
  line-height: ${(props) => props.lineHeight || '2.25rem'};
  margin: ${(props) => (props.margin ? props.margin : '15px 0')};
  text-align: ${(props) => props.textAlign || 'center'};
  width: ${(props) => props.width || '100%'};
  font-family: ${(props) => props.fontFamily || ''};

  ${(props) =>
    props.flexSpaceBetween &&
    'display: flex; justify-content: space-between; align-items: flex-start;'}

  span {
    ${(props) =>
      props.flexSpaceBetween &&
      'display: flex; align-items: start;flex-direction: column;'}
  }

  span > span {
    font-size: ${(props) =>
      `calc(${props.fontSize} / 2)` || `calc(1.625rem / 2)`};
  }

  @media (max-width: 980px) {
    font-size: ${(props) =>
      props.fontSizeMobile ? props.fontSizeMobile : '1.25rem'};
    line-height: ${(props) =>
      props.lineHeightMobile ? props.lineHeightMobile : '1.9375rem'};

    span > span {
      font-size: ${(props) =>
        `calc(${props.fontSizeMobile} / 2)` || `calc(1.25rem / 2)`};
    }
  }
`
