import { ContinueOnComputerPage } from '../../pages/ContinueOnComputerPage'
import { CountryPage } from '../../pages/CountryPage'
import { DeviceSwitchedPage } from '../../pages/DeviceSwitchedPage'
import { DocumentTypePage } from '../../pages/DocumentTypePage'
import { GeneralConditionsPage } from '../../pages/GeneralConditionsPage'
import { IncorrectPinCodePage } from '../../pages/IncorrectPinCodePage'
import { GoToMobileThankYouPage } from '../../pages/GoToMobileThankYouPage'
import { InitialPage } from '../../pages/InitialPage'
import { KlarnaPrivacyPolicyPage } from '../../pages/KlarnaPrivacyPolicyPage'
import { MicropaymentFintecPage } from '../../pages/MicropaymentFintecPage'
import { MicropaymentFintecAmexPage } from '../../pages/amex/MicropaymentFintecAmexPage'
import { MicropaymentInfoPage } from '../../pages/MicropaymentInfoPage'
import { MicropaymentInfoAmexPage } from '../../pages/amex/MicropaymentInfoAmexPage'
import { MicropaymentKlarnaPage } from '../../pages/MicropaymentKlarnaPage'
import { MocksPage } from '../../pages/MocksPage'
import { OtpPage } from '../../pages/OtpPage'
import { PixelSpeakPage } from '../../pages/PixelSpeakPage'
import { ProgressPage } from '../../pages/ProgressPage'
import { SignDocumentPage } from '../../pages/SignDocumentPage'
import { UserDataPage } from '../../pages/UserDataPage'
import { VideoIdPage } from '../../pages/VideoIdPage'
import { VideoOnboardingPage } from '../../pages/VideoOnboardingPage'
import { AisKlarnaPage } from '../../pages/dtp/AisKlarnaPage'
import { DTPWaitingPage } from '../../pages/dtp/DTPWaiting'
import { DTPInfoPage } from '../../pages/dtp/DTPInfoPage'
import { WaitingAfterGoToMobilePage } from '../../pages/WaitingAfterGoToMobilePage'
import { TermsAndConditionsDTPInfoPage } from '../../pages/dtp/TermsAndConditionsDTPInfoPage'
import { VerifyingPage } from '../../pages/VerifyingPage'
import { AccountHolderMockIkanoPage } from '../../pages/ikano/AccountHolderMockIkanoPage'
import { AddressUploadBackSideIkanoPage } from '../../pages/ikano/AddressUploadBackSideIkanoPage'
import { AddressUploadBothSidesIkanoPage } from '../../pages/ikano/AddressUploadBothSidesIkanoPage'
import { AddressUploadFrontSideIkanoPage } from '../../pages/ikano/AddressUploadFrontSideIkanoPage'
import { AddressUploadIkanoPage } from '../../pages/ikano/AddressUploadIkanoPage'
import { AddressVerificationIkanoPage } from '../../pages/ikano/AddressVerificationIkanoPage'
import { AddressVerificationTwoSidedIkanoPage } from '../../pages/ikano/AddressVerificationTwoSidedIkanoPage'
import { CompleteIkanoPage } from '../../pages/ikano/CompleteIkanoPage'
import { ContinueOnMobilePage } from '../../pages/ContinueOnMobilePage'
import { DocumentTypeIkanoPage } from '../../pages/ikano/DocumentTypeIkanoPage'
import { GeneralConditionsWithMicropaymentIkanoPage } from '../../pages/ikano/GeneralConditionsWithMicropaymentIkanoPage'
import { TermsAndConditionsWithMicropaymentIkanoPage } from '../../pages/ikano/TermsAndConditionsWithMicropaymentIkanoPage'
import { MicropaymentFintecIkanoPage } from '../../pages/ikano/MicropaymentFintecIkanoPage'
import { MicropaymentInfoIkanoPage } from '../../pages/ikano/MicropaymentInfoIkanoPage'
import { MicropaymentInfoIkanoOpenBankingPage } from '../../pages/ikano/MicropaymentInfoIkanoOpenBankingPage'
import { TermsAndConditionsMicropaymentInfoIkanoOpenBankingPage } from '../../pages/ikano/TermsAndConditionsMicropaymentInfoIkanoOpenBankingPage'
import { MicropaymentInfoWithVideoIdIkanoPage } from '../../pages/ikano/MicropaymentInfoWithVideoIdIkanoPage'
import { MicropaymentReversedInfoWithVideoIdIkanoPage } from '../../pages/ikano/MicropaymentReversedInfoWithVideoIdIkanoPage'
import { SignDocumentIkanoPage } from '../../pages/ikano/SignDocumentIkanoPage'
import { SwitchToMobilePage } from '../../pages/SwitchToMobilePage'
import { ResumeFromEmailPage } from '../../pages/ResumeFromEmailPage'
import { VideoIdIkanoPage } from '../../pages/ikano/VideoIdIkanoPage'
import { VideoIdIkanoPageStandalone } from '../../pages/ikano/VideoIdIkanoPageStandalone'
import { GeneralConditionsJitpay } from '../../pages/jitpay/GeneralConditionsJitpay'
import { GeneralConditionsJitpayQES } from '../../pages/jitpay/GeneralConditionsJitpayQES'
import { TermsAndConditionsJitpayPage } from '../../pages/jitpay/TermsAndConditionsJitpayPage'
import { MicropaymentInfoJitpayPage } from '../../pages/jitpay/MicropaymentInfoJitpayPage'
import { MicropaymentInfoJitpayPageStandalone } from '../../pages/jitpay/MicropaymentInfoJitpayPageStandalone'
import { MicropaymentKlarnaPageJitpay } from '../../pages/jitpay/MicropaymentKlarnaPageJitpay'
import { MicropaymentKlarnaPageJitpayStandalone } from '../../pages/jitpay/MicropaymentKlarnaPageJitpayStandalone'
import { ProgressPageJitpay } from '../../pages/jitpay/ProgressPageJitpay'
import { VideoIdPageJitpay } from '../../pages/jitpay/VideoIdPageJitpay'
import { VideoOnboardingJitPayPage } from '../../pages/jitpay/VideoOnboardingJitPayPage'
import { AddressUploadPageKontomatik } from '../../pages/kontomatik/AddressUploadPageKontomatik'
import { IntroKontomatikPage } from '../../pages/kontomatik/IntroKontomatikPage'
import { KontomatikPage } from '../../pages/kontomatik/KontomatikPage'
import { ProgressPageKontomatik } from '../../pages/kontomatik/ProgressPageKontomatik'
import { BankIdRetryPage } from '../../pages/BankIdRetryPage'
import { BankSelectionPage } from '../../pages/BankSelectionPage'
import { GocardlessBankSelectionPage } from '../../pages/GocardlessBankSelectionPage'
import { GeneralConditionsBeforeBankSelectionPage } from '../../pages/GeneralConditionsBeforeBankSelectionPage'
import { TermsAndConditionsPage } from '../../pages/TermsAndConditionsPage'
import { SaltedgeRedirectPage } from '../../pages/SaltedgeRedirectPage'
import { SaltedgeReturnPage } from '../../pages/SaltedgeReturnPage'
import { ErrorIosBrowserNotSupportedPage } from '../../pages/ErrorIosBrowserNotSupportedPage'
import { ErrorPage } from '../../pages/ErrorPage'
import { NotFoundPage } from '../../pages/NotFoundPage'
import { OpenbankingMastercardPage } from '@/pages/mastercard/OpenbankingMastercardPage'
import { SignDocumentBmwPage } from '@/pages/bmw/SignDocumentBmwPage'
import { SignDocumentSantanderPage } from '@/pages/santander/SignDocumentSantanderPage'

export const PAGES = {
  GeneralConditions: GeneralConditionsPage,
  SignDocument: SignDocumentPage,
  KlarnaPrivacyPolicy: KlarnaPrivacyPolicyPage,
  VideoId: VideoIdPage,
  Otp: OtpPage,
  DocumentType: DocumentTypePage,
  Country: CountryPage,
  UserData: UserDataPage,
  VideoOnboarding: VideoOnboardingPage,
  MicropaymentFintec: MicropaymentFintecPage,
  MicropaymentFintecAmex: MicropaymentFintecAmexPage,
  MicropaymentFintecIkano: MicropaymentFintecIkanoPage,
  MicropaymentKlarna: MicropaymentKlarnaPage,
  Mocks: MocksPage,
  Initial: InitialPage,
  Progress: ProgressPage,
  IntroKontomatik: IntroKontomatikPage,
  KontomatikPage: KontomatikPage,
  ProgressPageKontomatik: ProgressPageKontomatik,
  AddressUploadPageKontomatik: AddressUploadPageKontomatik,
  GeneralConditionsJitpay: GeneralConditionsJitpay,
  GeneralConditionsJitpayQES: GeneralConditionsJitpayQES,
  TermsAndConditionsJitpay: TermsAndConditionsJitpayPage,
  VideoIdJitpay: VideoIdPageJitpay,
  MicropaymentKlarnaJitpay: MicropaymentKlarnaPageJitpay,
  ProgressPageJitpay: ProgressPageJitpay,
  VideoOnboardingJitpay: VideoOnboardingJitPayPage,
  MicropaymentInfoJitpay: MicropaymentInfoJitpayPage,
  MicropaymentKlarnaJitpayStandalone: MicropaymentKlarnaPageJitpayStandalone,
  MicropaymentInfoJitpayStandalone: MicropaymentInfoJitpayPageStandalone,
  MicropaymentInfoIkano: MicropaymentInfoIkanoPage,
  MicropaymentInfoIkanoOpenBanking: MicropaymentInfoIkanoOpenBankingPage,
  TermsAndConditionsMicropaymentInfoIkanoOpenBanking:
    TermsAndConditionsMicropaymentInfoIkanoOpenBankingPage,
  GeneralConditionsWithMicropaymentIkano:
    GeneralConditionsWithMicropaymentIkanoPage,
  TermsAndConditionsWithMicropaymentIkano:
    TermsAndConditionsWithMicropaymentIkanoPage,
  MicropaymentInfoWithVideoIdIkano: MicropaymentInfoWithVideoIdIkanoPage,
  MicropaymentReversedInfoWithVideoIdIkano:
    MicropaymentReversedInfoWithVideoIdIkanoPage,
  VideoIdIkanoPageStandalone: VideoIdIkanoPageStandalone,
  VideoIdIkano: VideoIdIkanoPage,
  CompleteIkano: CompleteIkanoPage,
  SignDocumentBmw: SignDocumentBmwPage,
  SignDocumentIkano: SignDocumentIkanoPage,
  SignDocumentSantander: SignDocumentSantanderPage,
  PixelSpeakPage: PixelSpeakPage,
  DocumentTypeIkano: DocumentTypeIkanoPage,
  AddressVerificationIkano: AddressVerificationIkanoPage,
  AddressUploadIkano: AddressUploadIkanoPage,
  AddressVerificationTwoSidedIkano: AddressVerificationTwoSidedIkanoPage,
  AddressUploadFrontSideIkano: AddressUploadFrontSideIkanoPage,
  AddressUploadBackSideIkano: AddressUploadBackSideIkanoPage,
  AccountHolderMockIkano: AccountHolderMockIkanoPage,
  AddressUploadBothSidesIkano: AddressUploadBothSidesIkanoPage,
  AisKlarna: AisKlarnaPage,
  SwitchToMobile: SwitchToMobilePage,
  ContinueOnMobile: ContinueOnMobilePage,
  ResumeFromEmail: ResumeFromEmailPage,
  DTPWaiting: DTPWaitingPage,
  DTPInfo: DTPInfoPage,
  WaitingAfterGoToMobile: WaitingAfterGoToMobilePage,
  TermsAndConditionsDTPInfo: TermsAndConditionsDTPInfoPage,
  Verifying: VerifyingPage,
  IncorrectPinCode: IncorrectPinCodePage,
  GoToMobileThankYou: GoToMobileThankYouPage,
  DeviceSwitched: DeviceSwitchedPage,
  ContinueOnComputer: ContinueOnComputerPage,
  MicropaymentInfo: MicropaymentInfoPage,
  MicropaymentInfoAmex: MicropaymentInfoAmexPage,
  BankIdRetry: BankIdRetryPage,
  BankSelection: BankSelectionPage,
  GocardlessBankSelection: GocardlessBankSelectionPage,
  GeneralConditionsBeforeBankSelection:
    GeneralConditionsBeforeBankSelectionPage,
  TermsAndConditions: TermsAndConditionsPage,
  SaltedgeRedirect: SaltedgeRedirectPage,
  SaltedgeReturn: SaltedgeReturnPage,
  ErrorIosBrowserNotSupported: ErrorIosBrowserNotSupportedPage,
  NotFound: NotFoundPage,
  Error: ErrorPage,
  OpenbankingMastercard: OpenbankingMastercardPage,
}

export const PAGES_WITHOUT_KEEP_ALIVE = [
  'VideoId',
  'MicropaymentFintec',
  'MicropaymentKlarna',
  'MicropaymentKlarnaJitpay',
  'MicropaymentKlarnaJitpayStandalone',
  'AisKlarna',
  'VideoIdJitpay',
  'AddressUploadPageKontomatik',
  'Otp',
  'CompleteIkano',
  'VideoIdIkano',
  'ErrorPage',
  'Mocks',
  'IncorrectPinCode',
  'GoToMobileThankYou',
  'DeviceSwitched',
  'ContinueOnComputer',
  'ContinueOnMobile',
]
