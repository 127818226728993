import {
  Button,
  ContentContainer,
  DocumentCheckbox,
  Header,
  Icon,
  LoadingSpinner,
  PdfViewer,
  Popup,
} from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import { actionReport } from '../methods/actionReport'
import { axiosInstance } from '../methods/axiosConfig'
import { devLog } from '../methods/devLog'
import {
  ButtonImgWrap,
  ButtonText,
  Condition,
  ConditionTitle,
  RequiredFooter,
} from '../styles/generic.styles'
import { ContentContainerBottomFixed } from '../styles/layout.styles'
import { TickAllStyle } from '../styles/input.styles'
import {
  AcceptanceDocument,
  AcceptanceDocumentRes,
} from '../types/acceptanceDocument'

export const GeneralConditionsPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth, pageHeight } = store.AppState
  const { theme } = store.InterfaceState
  const { context, currentPageIndex, currentScenarioId } = store.ScenarioState
  const { generalConditionsPage: trans } = store.TranslationsState.translations

  // Checkboxes state
  const [terms, setTerms] = useState(false)
  const [policy, setPolicy] = useState(false)
  const [biometrics, setBiometrics] = useState(false)
  const [tickAll, setTickAll] = useState(false)

  // Popups visibility
  const [docPopupVisible, setDocPopupVisible] = useState(false)
  const [privPopupVisible, setPrivPopupVisible] = useState(false)
  const [biometricsPopupVisible, setBiometricsPopupVisible] = useState(false)

  // Documents fetched objects
  const [termsDoc, setTermsDoc] = useState<AcceptanceDocument>()
  const [policyDoc, setPolicyDoc] = useState<AcceptanceDocument>()
  const [biometricsDoc, setBiometricsDoc] = useState<AcceptanceDocument>()

  // Loadings state
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)

  // Mobile utilities
  const [isMobile, setIsMobile] = useState(false)
  const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
      setIsMobile(true)
    }
  }, [pageWidth, pageHeight])

  const getDocuments = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL

      const res = await axiosInstance.get<AcceptanceDocumentRes>(
        `${apiUrl}/electronic-id/acceptance-document`,
        { withCredentials: true }
      )

      const privacyPolicy = res.data.find(
        (item) => item.type === 'PRIVACY_POLICY'
      )
      const termsAndConditions = res.data.find(
        (item) => item.type === 'TERMS_AND_CONDITIONS'
      )
      const biometrics = res.data.find(
        (item) => item.type === 'BIOMETRIC_CONSENT'
      )

      setTermsDoc(termsAndConditions)
      setPolicyDoc(privacyPolicy)
      setBiometricsDoc(biometrics)

      setLoading(false)
    } catch (e) {
      devLog(e)
    }
  }

  const handleProceed = async () => {
    if (!(terms && policy)) return

    try {
      if (currentScenarioId !== 'localTests') {
        setBtnLoading(true)
        const apiUrl = process.env.WEB_API_URL

        await axiosInstance.post(
          `${apiUrl}/electronic-id/acceptance-document`,
          {
            acceptedDocuments: biometrics
              ? [policyDoc?.id, termsDoc?.id, biometricsDoc?.id]
              : [policyDoc?.id, termsDoc?.id],
          },
          {
            withCredentials: true,
          }
        )

        actionReport({
          type: 'event.onboarding-web.consent.CONSENT_ACCEPTED',
          payload: {},
        })

        setBtnLoading(false)
      }

      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    } catch (e) {
      devLog(e)
    }
  }

  const handleTerms = ({ checked: value }) => {
    setTerms(value)
    actionReport({
      type: 'event.onboarding-web.consent.TERMS_AND_CONDITIONS_CHECKED',
      payload: {},
    })
  }

  const handlePolicy = ({ checked: value }) => {
    setPolicy(value)
    actionReport({
      type: 'event.onboarding-web.consent.PRIVACY_POLICY_CHECKED',
      payload: {},
    })
  }

  const handleBiometrics = ({ checked: value }) => {
    setBiometrics(value)
    actionReport({
      type: 'event.onboarding-web.consent.BIOMETRIC_CONSENT_CHECKED',
      payload: {},
    })
  }

  const handleTickAll = () => {
    setTickAll(!tickAll)
    setBiometrics(!tickAll)
    setTerms(!tickAll)
    setPolicy(!tickAll)
  }

  useEffect(() => {
    if (context?.termsAndConditionsPresent) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    } else if (window.location.pathname.split('/')[1] !== 'mock') {
      getDocuments()
    }
  }, [])

  const isTouchScrollDisabled = () => {
    if (docPopupVisible || privPopupVisible || biometricsPopupVisible) {
      return 'none'
    } else {
      return 'auto'
    }
  }

  useEffect(() => {
    if (docPopupVisible === true) {
      actionReport({
        type: 'event.onboarding-web.consent.TERMS_AND_CONDITIONS_DISPLAYED',
        payload: {},
      })
    }
  }, [docPopupVisible])

  useEffect(() => {
    if (privPopupVisible === true) {
      actionReport({
        type: 'event.onboarding-web.consent.PRIVACY_POLICY_DISPLAYED',
        payload: {},
      })
    }
  }, [privPopupVisible])

  useEffect(() => {
    if (biometricsPopupVisible === true) {
      actionReport({
        type: 'event.onboarding-web.consent.BIOMETRIC_CONSENT_DISPLAYED',
        payload: {},
      })
    }
  }, [biometricsPopupVisible])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
      touchAction={isTouchScrollDisabled()}
    >
      {loading ? (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          height="68px"
          {...theme.loadingSpinner}
        />
      ) : (
        <>
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
          >
            {trans.header}
          </Header>

          {termsDoc?.id && (
            <Condition margin="30px 0 10px">
              <ConditionTitle
                required
                asteriskColor={theme.globals.inputRequired.asteriskColor}
              >
                {termsDoc?.title}
              </ConditionTitle>
              <DocumentCheckbox
                inputName="terms-checkbox"
                description={termsDoc?.agreement}
                externalUrl={termsDoc?.s3Url}
                handleDecision={handleTerms}
                value={terms}
                {...theme.documentCheckbox}
                margin="0 0 30px 0"
                contentFontsize="14px"
                iconSize="26px"
                onClick={() => setDocPopupVisible(true)}
              />
            </Condition>
          )}
          {policyDoc?.id && (
            <>
              <span className="spacer" />
              <Condition>
                <ConditionTitle
                  required
                  asteriskColor={theme.globals.inputRequired.asteriskColor}
                >
                  {policyDoc?.title}
                </ConditionTitle>
                <DocumentCheckbox
                  inputName="privacy-checkbox"
                  description={policyDoc?.agreement}
                  externalUrl={policyDoc?.s3Url}
                  handleDecision={handlePolicy}
                  value={policy}
                  {...theme.documentCheckbox}
                  margin="0 0 40px 0"
                  contentFontsize="14px"
                  iconSize="26px"
                  onClick={() => setPrivPopupVisible(true)}
                />
              </Condition>
            </>
          )}
          {biometricsDoc?.id && (
            <>
              <span className="spacer" />
              <Condition>
                <ConditionTitle>{biometricsDoc?.title}</ConditionTitle>
                <DocumentCheckbox
                  inputName="biometrics-checkbox"
                  description={biometricsDoc?.agreement}
                  externalUrl={biometricsDoc?.s3Url}
                  handleDecision={handleBiometrics}
                  value={biometrics}
                  {...theme.documentCheckbox}
                  margin="0 0 40px 0"
                  contentFontsize="14px"
                  iconSize="26px"
                  onClick={() => setBiometricsPopupVisible(true)}
                />
              </Condition>
            </>
          )}

          <ContentContainerBottomFixed>
            <RequiredFooter
              fontSize={theme.globals.inputRequired.textFontSize}
              color={theme.globals.inputRequired.textColor}
            >
              {trans.required}
            </RequiredFooter>

            {/* <TickAllStyle width="164px" paddingMobile="14px 10px">
              <input
                id="tick-all"
                type="checkbox"
                name="tick-all"
                checked={tickAll}
              />
              <span className="label-text" onClick={handleTickAll}>
                {trans.tickAll}
              </span>
            </TickAllStyle> */}

            <Button
              onClick={handleProceed}
              disabled={!(terms && policy)}
              {...theme.button}
              width="164px"
              paddingMobile="14px 40px"
              id="page-submit"
            >
              {btnLoading ? (
                <LoadingSpinner
                  width="16px"
                  {...theme.button.loadingSpinner}
                  padding="0 0"
                  thickness={2}
                />
              ) : (
                <>
                  <ButtonImgWrap>
                    <Icon size="18px" type="checkmark" />
                  </ButtonImgWrap>
                  <ButtonText>{trans.proceed}</ButtonText>
                </>
              )}
            </Button>
          </ContentContainerBottomFixed>
          {isMobile ? (
            <>
              <Popup
                visibility={docPopupVisible}
                handleVisibility={setDocPopupVisible}
                {...theme.popup}
              >
                <PdfViewer
                  file={termsDoc?.s3Url}
                  {...theme.pdfViewer}
                  viewerWidth="100%"
                  viewerHeight={containerPdfViewerHeight}
                  initialScale={1}
                />
              </Popup>
              <Popup
                visibility={privPopupVisible}
                handleVisibility={setPrivPopupVisible}
                {...theme.popup}
              >
                <PdfViewer
                  file={policyDoc?.s3Url}
                  {...theme.pdfViewer}
                  viewerWidth="100%"
                  viewerHeight={containerPdfViewerHeight}
                  initialScale={1}
                />
              </Popup>
              <Popup
                visibility={biometricsPopupVisible}
                handleVisibility={setBiometricsPopupVisible}
                {...theme.popup}
              >
                <PdfViewer
                  file={biometricsDoc?.s3Url}
                  {...theme.pdfViewer}
                  viewerWidth="100%"
                  viewerHeight={containerPdfViewerHeight}
                  initialScale={1}
                />
              </Popup>
            </>
          ) : (
            <>
              <Popup
                visibility={docPopupVisible}
                handleVisibility={setDocPopupVisible}
                {...theme.popup}
              >
                <PdfViewer
                  file={termsDoc?.s3Url}
                  {...theme.pdfViewer}
                  viewerWidth="100%"
                  viewerHeight={`calc(100vh - 405px)`}
                  initialScale={1}
                />
              </Popup>
              <Popup
                visibility={privPopupVisible}
                handleVisibility={setPrivPopupVisible}
                {...theme.popup}
              >
                <PdfViewer
                  file={policyDoc?.s3Url}
                  {...theme.pdfViewer}
                  viewerWidth="100%"
                  viewerHeight={`calc(100vh - 405px)`}
                  initialScale={1}
                />
              </Popup>

              <Popup
                visibility={biometricsPopupVisible}
                handleVisibility={setBiometricsPopupVisible}
                {...theme.popup}
              >
                <PdfViewer
                  file={biometricsDoc?.s3Url}
                  {...theme.pdfViewer}
                  viewerWidth="100%"
                  viewerHeight={`calc(100vh - 405px)`}
                  initialScale={1}
                />
              </Popup>
            </>
          )}
        </>
      )}
    </ContentContainer>
  )
})
