export const enpalTheme = {
  globals: {
    name: 'Enpal',
    fontFamily: '"IkanoSans", sans-serif',
    fontFamilyHeadline: '"IkanoSans", sans-serif',
    fontFamilyBold: '"IkanoSansBold", sans-serif',
    bgColor: 'rgba(248, 247, 246, 1)',
    linkColor: 'rgba(255, 176, 0, 1)',
    linkColorHover: 'rgba(255, 219, 90, 1)',
    checkboxColor: 'rgba(7, 37, 67, 1)',
    checkboxColorActive: 'rgba(255, 176, 0, 1)',
    inputRequired: {
      asteriskColor: 'rgba(62, 126, 255, 1)',
      textColor: 'rgba(62, 126, 255, 1)',
      textFontSize: '14px',
    },
  },
  contentWrap: {},
  container: {
    bgColor: '#FFFFFF',
    width: '100%',
    padding: '50px',
    borderRadius: '2px',
    border: '1px solid rgba(228, 228, 228, 1)',
    boxShadow: 'none',
    margin: '0 auto',
    color: 'rgba(7, 37, 67, 1)',
  },
  documentCheckbox: {
    iconColor: 'rgba(7, 37, 67, 1)',
    iconHover: 'rgba(7, 37, 67, 1)',
    width: '100%',
    contentFontSize: '14px',
    margin: '20px',
  },
  icon: {
    docVerifyDotsColor: 'rgba(255, 255, 255, 1)',
    mainIconColor: 'rgba(255, 176, 0, 1)',
    customIconColor: '#2BA107',
    colorHover: 'rgba(255, 176, 0, 1) ',
    size: '32px',
    thickness: '0.2px',
  },
  otpInput: {
    width: '100%',
    columnGap: '10px',
    marginTop: '10px',
    marginBottom: '30px',
    inputHeight: '60px',
    inputBorder: '2px solid rgba(206, 212, 220, 1)',
    borderRadius: '2px',
    fontSize: '22px ',
    focusColor: 'rgba(255, 176, 0, 1)',
    bgColor: 'rgba(255, 255, 255, 1)',
    focusBgColor: 'rgba(255, 255, 255, 1)',
    fontColor: 'rgba(7, 37, 67, 1)',
    loadingColor: 'rgba(255, 176, 0, 1)',
  },
  pdfViewer: {
    pageBackground: '#FFFFFF',
    containerBorder: '2px solid rgba(206, 212, 220, 1)',
    scrollTrackColor: '#CED4DC',
    scrollHandleColor: 'rgba(7, 37, 67, 1)',
    scrollRadius: '0px',
    toolbox: {
      color: '#FFFFFF',
      bgColor: 'rgba(0, 0, 0, 0.70)',
      borderRadius: '4px',
      padding: '5px',
      boxShadow: '#none',
    },
    zoomButton: {
      bgColor: '#FFFFFF',
      color: '#34353A',
      border: '1px solid #CECBD6',
      bgColorHover: '#F1F3F7',
      colorHover: '#34353A',
      borderHover: '1px solid #CECBD6',
    },
    pageRangeInput: {
      trackColor: '#CECBD6',
      activeTrackColor: '#34353A',
      thumbColor: '#34353A',
      thumbHoverColor: '#34353ACC',
      thumbFocusBorderColor: '',
    },
  },
  downloadDocumentButton: {
    bgColor: '#FFFFFF',
    color: '#34353A',
    border: '1px solid #CECBD6',
    bgColorHover: '#F1F3F7',
    colorHover: '#34353A',
    borderHover: '1px solid #CECBD6',
    bgColorDisabled: '#F6F6F6',
    colorDisabled: '#91969B',
    borderDisabled: '1px solid #F6F6F6',
  },
  button: {
    bgColor: 'rgba(255, 176, 0, 1)',
    padding: '10px 34px',
    borderRadius: '14px',
    width: 'auto',
    border: '1px solid rgba(255, 176, 0, 1)',
    boxShadow: 'none',
    margin: '0',
    color: 'rgba(7, 37, 67, 1)',
    colorHover: 'rgba(7, 37, 67, 1)',
    bgColorHover: 'rgba(255, 219, 90, 1)',
    borderHover: '1px solid rgba(255, 219, 90, 1)',
    bgColorDisabled: 'rgba(206, 212, 220, 1)',
    borderDisabled: '1px solid rgba(206, 212, 220, 1)',
    colorDisabled: 'rgba(7, 37, 67, 1)',
    fontSize: '16px',
    fontWeight: '700',
    loadingSpinner: {
      primary: 'rgba(7, 37, 67, 1)',
      bgColor: 'rgba(255, 176, 0, 1)',
    },
  },
  buttonSecondary: {
    bgColor: 'transparent',
    padding: '10px 34px',
    borderRadius: '14px',
    width: 'auto',
    border: '1px solid #DCDBE2',
    boxShadow: 'none',
    margin: '0',
    color: 'rgba(7, 37, 67, 1)',
    colorHover: 'rgba(7, 37, 67, 1)',
    bgColorHover: 'rgba(255, 219, 90, 1)',
    borderHover: '1px solid rgba(255, 219, 90, 1)',
    bgColorDisabled: 'rgba(206, 212, 220, 1)',
    borderDisabled: '1px solid rgba(206, 212, 220, 1)',
    colorDisabled: 'rgba(7, 37, 67, 1)',
    fontSize: '16px',
    fontWeight: '700',
  },
  buttonDisabled: {
    padding: '10px 34px',
    borderRadius: '14px',
    width: 'auto',
    boxShadow: 'none',
    margin: '0',
    fontSize: '16px',
    fontWeight: '700',
    bgColor: 'rgba(206, 212, 220, 1)',
    border: '1px solid rgba(206, 212, 220, 1)',
    color: 'rgba(7, 37, 67, 1)',
    bgColorHover: 'rgba(206, 212, 220, 1)',
    borderHover: '1px solid rgba(206, 212, 220, 1)',
    colorHover: 'rgba(7, 37, 67, 1)',
    cursor: 'not-allowed',
  },
  header: {
    color: 'rgba(7, 37, 67, 1)',
    fontSize: '1.625rem',
    lineHeight: '2.25rem',
    fontSizeMobile: '1.25rem',
    lineHeightMobile: '1.9375rem',
    margin: '15px 0',
    textAlign: 'center',
    width: '100%',
  },
  selectInput: {
    borderRadius: '2px',
    borderWidth: '1px',
    borderColorFocus: 'rgba(206, 212, 220, 1)',
    backgroundColorSelected: 'rgba(206, 212, 220, 1)',
    backgroundColorFocus: 'rgba(206, 212, 220, 0.75)',
    currentBg: 'rgba(206, 212, 220, 0.50)',
    indicatorColorFocus: 'rgb(102, 102, 102)',
    indicatorColorHover: 'rgb(153, 153, 153)',
    danger: 'rgba(226,64,40,1)',
    dangerLight: 'rgba(226,64,40,0.5)',
  },
  symbol: {
    primary: 'rgba(255, 176, 0, 1)',
    secondary: 'rgba(255, 255, 255, 1)',
    tenary: 'rgba(248, 247, 246, 1)',
  },
  textInput: {
    bgColor: 'white',
    padding: '14px',
    borderRadius: '2px',
    border: '2px solid rgba(206, 212, 220, 1)',
    boxShadow: 'none',
    margin: '0',
    color: 'rgba(7, 37, 67, 1)',
    colorFocus: 'rgba(7, 37, 67, 1)',
    bgColorFocus: 'white',
    borderFocus: '2px solid rgba(7, 37, 67, 1)',
    bgColorDisabled: 'rgba(206, 212, 220, 1)',
    borderDisabled: 'rgba(206, 212, 220, 1)',
    colorDisabled: 'rgba(206, 212, 220, 1)',
    fontSize: '14px',
    fontWeight: '300',
    outline: 'none',
    errorColor: '#d91111',
  },
  brandBar: {
    bgColor: 'white ',
    padding: '30px',
    margin: '0 0 30px 0',
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'normal',
  },
  wrapXs2a: {
    fontFamily:
      '"Nunito Sans",-apple-system,".SFNSText-Regular","San Francisco",BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif',
    fontFamilyHeadline:
      '"Nunito Sans",-apple-system,".SFNSText-Regular","San Francisco",BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif',
    bgColor: 'rgba(248, 247, 246, 1)',
    linkColor: 'rgba(255, 176, 0, 1)',
    linkColorHover: 'rgba(255, 219, 90, 1)',
    checkboxColor: 'rgba(7, 37, 67, 1)',
    checkboxColorActive: 'rgba(255, 176, 0, 1)',
    backButton: {
      borderColor: 'rgba(255, 176, 0, 1)',
      color: 'rgba(7, 37, 67, 1)',
      colorHover: 'rgba(7, 37, 67, 1)',
      disabled: 'rgba(206, 212, 220, 1)',
    },
    input: {
      bgColor: 'white',
      padding: '14px',
      borderRadius: '2px',
      border: '2px solid rgba(206, 212, 220, 1)',
      boxShadow: 'none',
      margin: '0',
      color: 'rgba(7, 37, 67, 1)',
      colorFocus: 'rgba(7, 37, 67, 1)',
      bgColorFocus: 'white',
      borderFocus: '2px solid rgba(7, 37, 67, 1)',
      bgColorDisabled: 'rgba(206, 212, 220, 1)',
      borderDisabled: 'rgba(206, 212, 220, 1)',
      colorDisabled: 'rgba(206, 212, 220, 1)',
      fontSize: '14px',
      fontWeight: '300',
      outline: 'none',
      errorColor: '#d91111',
    },
    button: {
      bgColor: 'rgba(255, 176, 0, 1)',
      padding: '10px 34px',
      paddingMobile: '14px 40px',
      borderRadius: '14px',
      width: 'auto',
      border: '1px solid rgba(255, 176, 0, 1)',
      boxShadow: 'none',
      margin: '0',
      color: 'rgba(7, 37, 67, 1)',
      colorHover: 'rgba(7, 37, 67, 1)',
      bgColorHover: 'rgba(255, 219, 90, 1)',
      borderHover: '1px solid rgba(255, 219, 90, 1)',
      bgColorDisabled: 'rgba(206, 212, 220, 1)',
      borderDisabled: '1px solid rgba(206, 212, 220, 1)',
      colorDisabled: 'rgba(7, 37, 67, 1)',
      fontSize: '16px',
      fontWeight: '700',
    },
  },
  wrapKlarna: {
    bgColor: 'rgba(206, 212, 220, 1)',
  },
  wrapKontomatik: {
    bgColor: '#FFF',
    textColor: 'rgba(7, 37, 67, 1)',
    borderColor: 'rgba(228, 228, 228, 1)',
    headerColor: 'rgba(7, 37, 67, 1)',
    primaryColor: 'rgb(236, 0, 0)',
    primaryReverseColor: '#FFF',
    secondaryColor: '586374',
    mainHoverColor: 'rgb(197, 15, 15)',
    mainHoverReverseColor: '#FFF',
    listHoverColor: '#F5F5F5',
    disabledColor: '#CED4DA',
    errorColor: '#BB0000',
    borderRadius: '2px',
    width: '100%',
    padding: '50px',
    boxShadow: 'none',
    margin: '0 auto',
  },
  progressBar: {
    time: 180,
    secondChance: true,
    showCounter: true,
    height: '17px',
    margin: '40px 0 10px 0',
    padding: '0 20px',
    border: '2px solid rgba(206, 212, 220, 1)',
    borderRadius: '0px',
    background: 'white',
    barFill: 'rgba(255, 176, 0, 1)',
    counterMargin: '20px 0',
    counterPadding: '0',
    counterFontSize: '24px',
    counterColor: 'rgba(7, 37, 67, 1)',
    infoMessageTextAlign: 'center',
    infoMessageFontSize: '12px',
    infoMessageMargin: '6px 0 0 0',
  },
  stepper: {
    borderColor: 'rgba(206, 212, 220, 1)',
    fontSizeTitle: '12px',
    fontSizeDesc: '10px',
  },
  loadingSpinner: {
    primary: 'rgba(255, 176, 0, 1)',
    bgColor: 'rgba(255, 255, 255, 1)',
  },
  tabSwitcher: {
    borderBottom: '2px solid #dbdbdb',
    buttonColor: '#9b9b9b',
    buttonBorderBottom: '4px solid rgba(255, 176, 0, 1)',
    buttonColorActive: '#000',
  },
  errorPage: {
    title: {
      color: 'rgba(7, 37, 67, 1)',
    },
    subtitle: {
      color: '#98959f',
    },
    svg: {
      fill1: 'none',
      fill2: '#ffffff',
      fill3: '#000',
      fill4: 'rgba(255, 176, 0, 1)',
    },
  },
  popup: {
    clickOutsideEnabled: true,
  },
  videoIdCallToActionButton: {
    bgColor: 'rgba(255, 176, 0, 1)',
    padding: '10px 34px',
    borderRadius: '14px',
    width: '150px',
    border: '1px solid rgba(255, 176, 0, 1)',
    boxShadow: 'none',
    margin: '32px 0 0 0',
    color: 'rgba(7, 37, 67, 1)',
    colorHover: 'rgba(7, 37, 67, 1)',
    bgColorHover: 'rgba(255, 219, 90, 1)',
    borderHover: '1px solid rgba(255, 219, 90, 1)',
    bgColorDisabled: 'rgba(206, 212, 220, 1)',
    borderDisabled: '1px solid rgba(206, 212, 220, 1)',
    colorDisabled: 'rgba(7, 37, 67, 1)',
    fontSize: '16px',
    fontWeight: '700',
    textDecoration: 'none',
  },
  videoIdPhoneChangeButton: {
    color: '#EC0000',
  },
}
