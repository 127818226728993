import React, { FC, useContext, useEffect, useState } from 'react'
import {
  Button,
  Header,
  SelectInput,
  Base64File,
  Base64FileInput,
} from '../Generic'
import { observer } from 'mobx-react'
import { StoreContext } from '../../components/App'
import { axiosInstance } from '../../methods/axiosConfig'
import { devLog } from '../../methods/devLog'
import {
  ButtonText,
  FileDeselect,
  FileError,
  FileInfo,
  FileName,
  FileSize,
  FileUpload,
  HorizontalBorder,
  SelectHolder,
  UnderlinedBold,
  UploadIcon,
  UploadParagraph,
  UploadAddressParagraph,
  UploadTitle,
  BoldText,
} from '../../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MobileSpacerForContentContainerBottomFixed,
  MarginOnly,
} from '../../styles/layout.styles'
import { forceRedirect } from '../../methods/forceRedirect'
import { residenceProofType } from '../../methods/getOnboardingContext'

interface ResidenceProofRetryScenarioProps {
  setLoading: (loading: boolean) => void
}

export const ResidenceProofRetryScenario: FC<ResidenceProofRetryScenarioProps> =
  observer(({ setLoading }) => {
    const store = useContext(StoreContext)
    const { pageWidth } = store.AppState
    const { theme } = store.InterfaceState
    const { context } = store.ScenarioState
    const { language } = store.TranslationsState
    const { pixelSpeak: trans } = store.TranslationsState.translations

    const [mobileVersion, setMobileVersion] = useState(false)
    const [fileSelected, setFileSelected] = useState<Base64File>()
    const [fileError, setFileError] = useState('')
    const [fileInputKey, setFileInputKey] = useState(Date.now())
    const [isFileBeingDragged, setIsFileBeingDragged] = useState(false)

    const [isDisabled, setIsDisabled] = useState(true)
    const [utilityBillType, setUtilityBillType] = useState(null)
    const [utilityBillTypeId, setUtilityBillTypeId] = useState(0)

    const allDocumentTypes = {
      ...trans.documentTypes,
      ...trans.payslipDocumentTypes,
    }
    const documentTypesOptions = Object.keys(allDocumentTypes)
      .filter((item) =>
        context.availableResidenceProofTypes.includes(
          item as residenceProofType
        )
      )
      .filter((key) => key !== context?.previousAttempt?.residenceProofType)
      .map((key) => ({
        value: key,
        label: allDocumentTypes[key],
      }))

    useEffect(() => {
      setUtilityBillType(documentTypesOptions[utilityBillTypeId])
    }, [language])

    const resetFileInput = () => {
      setFileInputKey(Date.now())
      setFileSelected(undefined)
      setFileError('')
      setIsDisabled(true)
      setIsFileBeingDragged(false)
    }

    const handleDragEnter = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setIsFileBeingDragged(true)
    }

    const handleDragLeave = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setIsFileBeingDragged(false)
    }

    useEffect(() => {
      document.addEventListener('dragover', handleDragEnter, false)
      document.addEventListener('dragleave', handleDragLeave, false)

      return () => {
        document.removeEventListener('dragover', handleDragEnter, false)
        document.removeEventListener('dragleave', handleDragLeave, false)
      }
    })

    const handleProceed = async () => {
      if (isDisabled) {
        setFileError('Bitte lade ein gültiges Dokument hoch.')
      } else {
        setLoading(true)
        await handleFile()

        forceRedirect(`${process.env.WEB_API_URL}/pixel-speak/complete`)
      }
    }

    const getFiles = async (file) => {
      setFileSelected(file)
    }

    const handleFile = async () => {
      try {
        await axiosInstance.post(
          context.documentUploadUrl,
          {
            file: fileSelected.base64,
            type: utilityBillType.value,
            fileName: fileSelected.name,
          },
          {
            withCredentials: true,
          }
        )
      } catch (e) {
        devLog(e)
      }
    }

    useEffect(() => {
      setIsDisabled(true)
      if (fileSelected?.base64) {
        if (
          fileSelected.type !== 'application/pdf' &&
          fileSelected.type !== 'image/jpeg' &&
          fileSelected.type !== 'image/png'
        ) {
          setFileError(trans.fileErrorType)
        } else if (fileSelected.sizeMB >= 5) {
          setFileError(trans.fileErrorSize)
        } else {
          setFileError('')
          setIsDisabled(false)
        }
      } else {
        resetFileInput()
      }
    }, [fileSelected, fileSelected?.base64])

    const selectChangeHandle = (e) => {
      const index = documentTypesOptions.findIndex(
        (item) => item.value === e.value
      )
      setUtilityBillType(e)
      setUtilityBillTypeId(index)
    }

    useEffect(() => {
      if (pageWidth && pageWidth <= 980) {
        setMobileVersion(true)
      }
    }, [pageWidth])

    return (
      <>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.headerResidenceProofRetry}
        </Header>

        <MarginOnly margin="20px 0 10px 0">
          <UploadParagraph>
            {
              trans.addressDescriptionResidenceProofRetry?.split(
                '<NAME OF FILE>'
              )[0]
            }
            <MarginOnly margin="20px 0" display="block" />
            <BoldText>{context?.previousAttempt?.documentFileName}</BoldText>
            <MarginOnly margin="20px 0" display="block" />
            {
              trans.addressDescriptionResidenceProofRetry?.split(
                '<NAME OF FILE>'
              )[1]
            }
            <br />
            <UploadAddressParagraph fontFamily={theme.globals.fontFamilyBold}>
              {context?.expectedAddress?.street}
            </UploadAddressParagraph>
            <UploadAddressParagraph fontFamily={theme.globals.fontFamilyBold}>
              {context?.expectedAddress?.postalCode}{' '}
              {context?.expectedAddress?.city}
            </UploadAddressParagraph>
          </UploadParagraph>
        </MarginOnly>

        <MarginOnly margin="10px 0 20px 0">
          <UploadParagraph>
            {trans.descriptionResidenceProofRetry}
          </UploadParagraph>
        </MarginOnly>

        <HorizontalBorder />

        <SelectHolder>
          <SelectInput
            options={documentTypesOptions}
            value={documentTypesOptions[utilityBillTypeId]}
            onChange={(e) => selectChangeHandle(e)}
            {...theme.selectInput}
          />
        </SelectHolder>

        <>
          <FileUpload isFile={fileSelected ? true : false}>
            <Base64FileInput
              key={fileInputKey}
              multiple={false}
              onChange={getFiles}
            />
            {fileSelected ? (
              <FileInfo>
                <FileName>{fileSelected.name}</FileName>
                <FileDeselect onClick={() => resetFileInput()}>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.74991 1.7832L5.24991 5.2832L8.74991 1.7832"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M8.74991 8.7832L5.24991 5.2832L1.74991 8.7832"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </FileDeselect>
              </FileInfo>
            ) : (
              <>
                <UploadIcon>
                  {isFileBeingDragged ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <rect width="48" height="48" rx="10" fill="white" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24 9C15.7157 9 9 15.7157 9 24C9 32.2843 15.7157 39 24 39C32.2843 39 39 32.2843 39 24C39 15.7157 32.2843 9 24 9ZM11 24C11 16.8203 16.8203 11 24 11C31.1797 11 37 16.8203 37 24C37 31.1797 31.1797 37 24 37C16.8203 37 11 31.1797 11 24Z"
                        fill={theme.icon.mainIconColor}
                      />
                      <rect
                        x="23"
                        y="17"
                        width="2"
                        height="14"
                        rx="1"
                        fill={theme.icon.mainIconColor}
                      />
                      <rect
                        x="31"
                        y="23"
                        width="2"
                        height="14"
                        rx="1"
                        transform="rotate(90 31 23)"
                        fill={theme.icon.mainIconColor}
                      />
                    </svg>
                  ) : (
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="48" height="48" rx="10" fill="white" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.9375 11.0001C19.283 11.0001 16.2654 13.7272 15.8095 17.2574C13.6837 17.3881 12 19.1544 12 21.3126C12 23.5563 13.8188 25.3751 16.0625 25.3751H29.8125C32.7465 25.3751 35.125 22.9972 35.125 20.0626C35.125 17.1281 32.7465 14.7501 29.8125 14.7501C29.6849 14.7501 29.558 14.755 29.4326 14.7635L29.2662 14.7782C28.0516 12.5284 25.6731 11.0001 22.9375 11.0001ZM28.1313 16.8859L29.5864 16.7577C29.6657 16.7527 29.7411 16.7501 29.8125 16.7501C31.642 16.7501 33.125 18.2328 33.125 20.0626C33.125 21.8925 31.642 23.3751 29.8125 23.3751H16.0625C14.9234 23.3751 14 22.4517 14 21.3126C14 20.2175 14.8552 19.3199 15.9322 19.2537L17.5814 19.1524L17.793 17.5136C18.1218 14.9679 20.3013 13.0001 22.9375 13.0001C24.909 13.0001 26.6269 14.0993 27.5063 15.7283L28.1313 16.8859ZM27.4571 30.6679L23.75 26.9608L20.0429 30.6679C19.6524 31.0584 19.6524 31.6916 20.0429 32.0821C20.4334 32.4727 21.0666 32.4727 21.4571 32.0821L22.75 30.7892V37C22.75 37.5523 23.1977 38 23.75 38C24.3023 38 24.75 37.5523 24.75 37V30.7892L26.0429 32.0821C26.4334 32.4727 27.0666 32.4727 27.4571 32.0821C27.8476 31.6916 27.8476 31.0584 27.4571 30.6679Z"
                        fill={theme.icon.mainIconColor}
                      />
                    </svg>
                  )}
                </UploadIcon>
                <UploadTitle>
                  {isFileBeingDragged ? (
                    <span>{trans.dropHere}</span>
                  ) : (
                    <span>
                      {trans.dragAndDrop}
                      <UnderlinedBold fontFamily={theme.globals.fontFamilyBold}>
                        {trans.chooseFile}
                      </UnderlinedBold>
                      {trans.filetypes}
                    </span>
                  )}
                </UploadTitle>
              </>
            )}
          </FileUpload>

          {fileError && <FileError>{fileError}</FileError>}

          <FileSize fontFamily={theme.globals.fontFamilyBold}>
            {trans.maxFilesize}
            <span>{trans.maxFilesizeValue}</span>
          </FileSize>
        </>

        <ContentContainerBottomFixed>
          {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
          <Button
            onClick={() => handleProceed()}
            {...theme[isDisabled ? 'buttonDisabled' : 'button']}
            width="164px"
            paddingMobile="14px 40px"
          >
            <>
              <ButtonText>{trans.proceed}</ButtonText>
            </>
          </Button>
        </ContentContainerBottomFixed>
        <MobileSpacerForContentContainerBottomFixed />
      </>
    )
  })
