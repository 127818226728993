import axios from 'axios'
import { apiUrl } from '../constants/apiUrl'
import { ConfigurationNames } from '../store/Scenario.store'
import { devLog } from './devLog'

export type residenceProofType = 'energy_bill' | 'telecom_bill' | 'pay_slip'

export interface ContextData {
  language: string
  configurationId?: ConfigurationNames
  shouldAskForEmail?: boolean
  shouldAskForPhoneNumber?: boolean
  shouldSelectDocument?: boolean
  showVideoIdHintsScreen?: boolean
  shouldUploadBankStatement?: boolean
  env?: string
  documentType?: string
  documentUploadUrl?: string
  providerClientId?: string
  bankStatementUpload?: string
  showTransactionCompletedWaitingScreen?: boolean
  flowType?: string
  wizzardSessionKey?: string
  startSessionUrl?: string
  shouldMockSenderHolder?: boolean
  abTestConfig?: { variant?: string }
  termsAndConditionsPresent?: boolean
  expectedAddress?: {
    city?: string
    street?: string
    postalCode?: string
  }
  isRetryAttempt?: boolean
  previousAttempt?: {
    documentType?: string
    documentFileName?: string
    residenceProofType?: residenceProofType
  }
  isPaySlip?: boolean
  availableResidenceProofTypes?: residenceProofType[]
  forceScrollingBeforeDocumentAccept?: boolean
  cancelSurveyScreenEnabledServiceList?: string[]
  instructionalVideoUrl?: string
}

type ContextNames =
  | 'videoId'
  | 'address-verification'
  | 'micropayment-fintec'
  | 'micropayment-klarna'
  | 'kontomatik'
  | 'onboarding'

const contextUrlMap = {
  onboarding: process.env.ONBOARDING_WEB_API_PATH,
  videoId: 'electronic-id',
  'micropayment-klarna': 'klarna-kosma-pisp',
  'micropayment-fintec': 'micropayment',
  // kontomatik: 'kontomatik',
  'address-verification': 'pixel-speak',
}

export async function getOnboardingContext(
  contextId: ContextNames
): Promise<ContextData> {
  try {
    const response = await axios.get(
      `${apiUrl}/${contextUrlMap[contextId] || contextId}/get-context`,
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    devLog(e)
  }
}
