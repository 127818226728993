import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 50px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 980px) {
    flex-direction: column;
    gap: 0px;

    & > div:last-child {
      position: absolute;
      right: 0;
      top: 30px;
    }
  }
`

export const ProgressContainer = styled.div<{ $showSubheader?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: ${({ $showSubheader }) =>
    $showSubheader ? '20px 36px 14px' : '20px 36px'};
  gap: 8px 20px;

  grid-template-areas: ${({ $showSubheader }) =>
    $showSubheader
      ? `
    'index progress'
    'title title'
    'subtitle subtitle'
  `
      : `
    'index progress'
    'title title'
  `};

  @media (max-width: 980px) {
    grid-template-columns: 120px auto;
    grid-template-rows: ${({ $showSubheader }) =>
      $showSubheader ? '20px 54px 14px' : '20px 54px'};
    gap: 8px 20px;
  }
`

export const ProgressIndex = styled.div<{ fontSize?: string; color?: string }>`
  grid-area: index;
  color: ${({ color }) => color || '#4a4a4a'};
  font-size: 13px;
  align-self: center;
  justify-self: start;
`

export const ProgressBar = styled.div<{
  color: string
}>`
  grid-area: progress;
  width: 100%;
  height: 4px;
  align-self: center;
  justify-self: center;
  background-color: ${({ color }) => color || '#CECBD6'};
  border-radius: 2px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const FilledBar = styled.div<{
  progress: number
  color: string
}>`
  height: 100%;
  width: ${({ progress }) => Math.min(Math.max(progress, 0), 1) * 100}%;
  background-color: ${({ color }) => color || '#2a0760'};
  transition: width 0.5s ease-in-out;
  border-radius: 2px;
`

export const ProgressBarsContainer = styled.div`
  grid-area: progress;
  width: 100%;
  height: 4px;
  display: flex;
  gap: 10px;
  align-self: center;
  justify-self: center;
`

export const SingleBar = styled.div<{
  active?: boolean
  primaryColor?: string
}>`
  flex-grow: 1;
  height: 100%;
  background-color: ${({ active, primaryColor }) =>
    active ? primaryColor : '#CECBD6'};
  border-radius: 2px;
`

export const ProgressTitle = styled.div<{
  color?: string
  fontSize?: string
  lineHeight?: string
  fontSizeMobile?: string
  lineHeightMobile?: string
  margin?: string
  width?: string
}>`
  color: ${({ color }) => color || '#4a4a4a'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: ${({ lineHeight }) => lineHeight || '20px'};
  margin: ${({ margin }) => margin || '0'};
  width: 100%;
  font-weight: 400;
  align-self: center;
  justify-self: start;
  grid-area: title;
  height: 36px;
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  @media (max-width: 980px) {
    gap: 0;
    height: 64px;
    flex-direction: column;
    font-size: ${({ fontSizeMobile }) => fontSizeMobile || '14px'};
    line-height: ${({ lineHeightMobile }) => lineHeightMobile || '20px'};
  }
`

export const ProgressSubTitle = styled.div<{
  color?: string
  fontSize?: string
  lineHeight?: string
  fontSizeMobile?: string
  lineHeightMobile?: string
  margin?: string
  width?: string
}>`
  color: ${({ color }) => color || '#4a4a4a'};
  font-size: ${({ fontSize }) => `calc(${fontSize} / 2)` || `calc(14px / 2)`};
  line-height: ${({ lineHeight }) =>
    `calc(${lineHeight} / 2)` || `calc(14px / 2)`};
  margin: 0;
  width: 100%;
  font-weight: 400;
  align-self: center;
  justify-self: start;
  grid-area: subtitle;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 980px) {
    flex-direction: column;
    font-size: ${({ fontSizeMobile }) =>
      `calc(${fontSizeMobile} / 2)` || `calc(14px / 2)`};
    line-height: ${({ lineHeightMobile }) =>
      `calc(${lineHeightMobile} / 2)` || `calc(14px / 2)`};
  }
`

export const ProgressTitleHeader = styled.div<{
  width?: string
}>`
  font-weight: 600;
  height: 32px;
  width: fit-content;
  flex: 0 0 auto;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 980px) {
    width: ${({ width }) => `${width - 120}px` || '100%'};
  }
`

export const ProgressTitleDocumentName = styled.div<{
  width?: string
  isVisible?: boolean
}>`
  height: 32px;
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 980px) {
    width: ${({ width }) => `${width - 120}px` || '100%'};
  }

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`

export const ZoomControlsWrappper = styled.div`
  align-self: flex-start;
`
