import {
  Button,
  ContentContainer,
  Header,
  Icon,
  Symbol,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../components/App'
import {
  ButtonImgWrap,
  ButtonText,
  OnboardingDoubleLine,
  OnboardingIcon,
  OnboardingItem,
  OnboardingItemHeading,
  OnboardingList,
} from '../../styles/generic.styles'
import {
  OnboardingWarning,
  WarnLeft,
  WarnRight,
} from '../../styles/jitpay.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MobileSpacerForContentContainerBottomFixed,
} from '../../styles/layout.styles'

export const VideoOnboardingJitPayPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState

  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState

  const { jitPayVideoOnboardingPage: trans } =
    store.TranslationsState.translations

  const handleProceed = async () => {
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  const [mobileVersion, setMobileVersion] = useState(false)

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    } else {
      setMobileVersion(false)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.header}
      </Header>

      <OnboardingList>
        <OnboardingItem className={theme.symbol.folder}>
          <OnboardingIcon size={theme.symbol.size || '58px'}>
            <Symbol
              type="lightbulb"
              {...theme.symbol}
              size={theme.symbol.size || '58px'}
            />
          </OnboardingIcon>
          <OnboardingDoubleLine>
            <OnboardingItemHeading>{trans.lightHeading}</OnboardingItemHeading>
            <span>{trans.lightContent}</span>
          </OnboardingDoubleLine>
        </OnboardingItem>

        <OnboardingItem className={theme.symbol.folder}>
          <OnboardingIcon size={theme.symbol.size || '58px'}>
            <Symbol
              type="globe"
              {...theme.symbol}
              size={theme.symbol.size || '58px'}
            />
          </OnboardingIcon>
          <OnboardingDoubleLine>
            <OnboardingItemHeading>{trans.netHeading}</OnboardingItemHeading>
            <span>{trans.netContent}</span>
          </OnboardingDoubleLine>
        </OnboardingItem>
        <OnboardingItem className={theme.symbol.folder}>
          <OnboardingIcon size={theme.symbol.size || '58px'}>
            <Symbol
              type="camera"
              {...theme.symbol}
              size={theme.symbol.size || '58px'}
            />
          </OnboardingIcon>
          <OnboardingDoubleLine>
            <OnboardingItemHeading>{trans.deviceHeading}</OnboardingItemHeading>
            <span>{trans.deviceContent}</span>
          </OnboardingDoubleLine>
        </OnboardingItem>
      </OnboardingList>

      <OnboardingWarning>
        <WarnLeft>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="23"
            viewBox="0 0 26 23"
            fill="none"
          >
            <path
              d="M13 9C13.8106 9 14.4509 9.6877 14.3931 10.4962L14.0712 15.0025C14.0311 15.5646 13.5634 16 13 16C12.4366 16 11.9689 15.5646 11.9288 15.0025L11.6069 10.4962C11.5491 9.6877 12.1894 9 13 9Z"
              fill="#FF8F8F"
            />
            <path
              d="M13 17C12.4477 17 12 17.4477 12 18C12 18.5523 12.4477 19 13 19C13.5523 19 14 18.5523 14 18C14 17.4477 13.5523 17 13 17Z"
              fill="#FF8F8F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.7276 1.96153C13.9558 0.638517 12.0442 0.638514 11.2724 1.96152L0.754516 19.9923C-0.0232497 21.3256 0.938492 23 2.48207 23H23.5179C25.0615 23 26.0233 21.3256 25.2455 19.9923L14.7276 1.96153ZM13.4319 3.70965C13.239 3.3789 12.7611 3.3789 12.5681 3.70965L2.9207 20.2481C2.72626 20.5814 2.9667 21 3.35259 21H22.6474C23.0333 21 23.2737 20.5814 23.0793 20.2481L13.4319 3.70965Z"
              fill="#FF8F8F"
            />
          </svg>
        </WarnLeft>
        <WarnRight>{trans.oneCameraContent}</WarnRight>
      </OnboardingWarning>

      <ContentContainerBottomFixed>
        {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
        <Button
          onClick={() => handleProceed()}
          {...theme.button}
          width={mobileVersion ? '100%' : '164px'}
          paddingMobile="14px 40px"
        >
          <>
            <ButtonImgWrap>
              <Icon size="18px" type="checkmark" />
            </ButtonImgWrap>
            <ButtonText>{trans.proceed}</ButtonText>
          </>
        </Button>
      </ContentContainerBottomFixed>
      <MobileSpacerForContentContainerBottomFixed />
    </ContentContainer>
  )
})
